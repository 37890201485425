const BASE_URL = 'contextual/'

export const CREATE_DEAL_LINK = (id) =>  `${BASE_URL}create-deal/${id ?? ''}`
export const CHOOSE_MEDIA_LINK = (id) =>   `${BASE_URL}choose-media/${id ?? ''}`

export const BUILD_AWESOME_AD_LINK = (id) => `${BASE_URL}build-awesome-creative/${id ?? ''}`

export const BUILD_YOURSELF_LINK = (id) => `${BASE_URL}ad-builder/${id ?? ''}`
export const BRIEF_BIG_LINK = (id) => `${BASE_URL}brief-big/${id ?? ''}`
export const DEAL_SUMMARY_LINK = (id) => `${BASE_URL}summary/${id ?? ''}`
export const DEAL_CREATIVES_LINK = (id) => `my-deals/creatives?ad_id=${id ?? ''}`
export const DEAL_BRIEFS_LINK = (id) => `my-deals/briefs?ad_id=${id ?? ''}`
export const DEAL_AUDIENCES_LINK = (id) => `my-deals/audience?ad_id=${id ?? ''}`
export const ACTIVITY_REPORT_LINK = (id) => `creative-report/${id ?? ''}`




import { NotificationsConstants } from './notifications.contants';

export const notifications = (state = { checking: true, authenticated: false }, action) => {

    switch (action.type) {

        case NotificationsConstants.NOTIFICATIONS_LOADING:
            return { ...state, loading: action.payload }
        case NotificationsConstants.GET_NOTIFICATIONS:
            return { ...state,  ...action.payload  }
        case NotificationsConstants.MARK_NOTIFICATION_READ:
            return { ...state,  readStatus: true, ...action.payload  }
        case NotificationsConstants.MARK_NOTIFICATION_READ_ERROR:
            return { ...state,  readStatus: false, data: state?.data }
        case NotificationsConstants.NEW_NOTIFICATION:
            return { ...state, data: [action.payload, ...state?.data || []]  }
        default:
            return state;
    }
};
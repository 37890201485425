import axios from 'axios';

import { NotificationsConstants } from './notifications.contants'
import { config } from '../../../helpers'

const ROOT_URL = config.apiServer;
const ROOT_URL_API = ROOT_URL + "/api";

const loading = (status) => {
    return {
        type: NotificationsConstants.NOTIFICATIONS_LOADING,
        payload: status
    };
};
export const newNotification = (notif) => {
    return (dispatch) => {
        dispatch({ type: NotificationsConstants.NEW_NOTIFICATION, payload: notif });
    }
}
export const getNotifications = (id)=> {
    return (dispatch) => {
        dispatch(loading(true));
        axios.get(`${ROOT_URL_API}/notifications?userId=${id}`,  { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: NotificationsConstants.GET_NOTIFICATIONS, payload: response.data});

                }
                else {
                    dispatch({ type: NotificationsConstants.GET_NOTIFICATIONS, payload: {} });
                }
                dispatch(loading(false));

            }).catch(() => {
                dispatch(loading(false));
                dispatch({ type: NotificationsConstants.GET_NOTIFICATIONS, payload: {} });
            });
    }
}
export const markNotificationRead = (id, newArr, isRead = true, isAll = false) => {
    return (dispatch) => {
        // dispatch(loading(true));
        let link = isAll ? `update-notifications/${id}` : `notifications/${id}`
        axios.post(`${ROOT_URL_API}/${link}`, {is_read: isRead}, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: NotificationsConstants.MARK_NOTIFICATION_READ, payload: {data: newArr} });
                }
                else {
                    dispatch({ type: NotificationsConstants.MARK_NOTIFICATION_READ_ERROR, payload: false });
                }
                // dispatch(loading(false));
            }).catch(() => {
                dispatch({ type: NotificationsConstants.MARK_NOTIFICATION_READ_ERROR, payload: false });
                // dispatch(loading(false));
            });
    };
}
import { AnalyticsConstants } from "./analytics.constants";

export const analytics = (state = {}, action) => {
    switch (action.type) {
        case AnalyticsConstants.GET_ALL_ANALYTICS_SUCCESS: return { ...state, ...action.payload }
        case AnalyticsConstants.GET_ALL_ANALYTICS_ERROR: return { ...state, ...action.payload, analytics: "" }

        case AnalyticsConstants.EMPTY_ANALYTICS: return { ...state, analytics: "", summary: "" }

        case AnalyticsConstants.ANALYTICS_LOADING_STATUS: return { ...state, loading: action.payload }
        
        case AnalyticsConstants.GET_ALL_COMPANIES: return { ...state, allCompanies: action.payload }
        case AnalyticsConstants.GET_ALL_COMPANIES_ERROR: return { ...state, allCompanies: "" }
        case AnalyticsConstants.GET_ALL_COMPANIES_LOADING: return { ...state, allCompaniesLoading: action.payload }
        
        case AnalyticsConstants.ANALYTICS_RESET_PASSWORD:
            return { ...state, resetPasswordResp: action.payload, resetPasswordStatus: true }
        case AnalyticsConstants.ANALYTICS_RESET_PASSWORD_ERROR:
            return { ...state, resetPasswordResp: action.payload, resetPasswordStatus: false }
        
        case AnalyticsConstants.ANALYTICS_RESEND_EMAIL:
            return { ...state, resendEmailResp: action.payload, resendEmailStatus: true }
        case AnalyticsConstants.ANALYTICS_RESEND_EMAIL_ERROR:
            return { ...state, resendEmailResp: action.payload, resendEmailStatus: false }

        default:
            return state;
    }
};

export const AnalyticsConstants = {
    GET_ALL_ANALYTICS_SUCCESS: 'GET_ALL_ANALYTICS_SUCCESS',
    GET_ALL_ANALYTICS_ERROR: 'GET_ALL_ANALYTICS_ERROR',
    ANALYTICS_LOADING_STATUS: 'ANALYTICS_LOADING_STATUS',

    GET_ALL_COMPANIES: 'GET_ALL_COMPANIES',
    GET_ALL_COMPANIES_ERROR: 'GET_ALL_COMPANIES_ERROR',
    GET_ALL_COMPANIES_LOADING: 'GET_ALL_COMPANIES_LOADING',
    
    ANALYTICS_RESET_PASSWORD: 'ANALYTICS_RESET_PASSWORD',
    ANALYTICS_RESET_PASSWORD_ERROR: 'ANALYTICS_RESET_PASSWORD_ERROR',

    ANALYTICS_RESEND_EMAIL: 'ANALYTICS_RESEND_EMAIL',
    ANALYTICS_RESEND_EMAIL_ERROR: 'ANALYTICS_RESEND_EMAIL_ERROR',

    EMPTY_ANALYTICS: 'EMPTY_ANALYTICS',
}
import { combineReducers } from 'redux';

import { users } from "./all-users/all-users.reducer";
import { dimension } from "./dimension/dimension.reducer"
import { userRoles } from "./userRoles/userRoles.reducer"
import { curatedMediaSettings } from './curated-media/media.reducer'
import { faqs } from './faq/faq.reducer'
import { billings } from './billing/billing.reducer'
import { superFormat } from './superFormat/superFormat.reducer'
import {customFormat} from "./custom-format/customFormat.reducer"
import { adTypeFormat } from "./format-type-tier/adTypeFormat.reducer"
import { carouselFormat } from './carousel-format/carouselFormat.reducer';
import { contextuals } from "./contextual-media/contextual.reducer"
import { bespokes } from "./bespoke/bespoke.reducer"
import { mediagrid } from './mediagrid/mediagrid.reducer';
import { lumen } from './lumen/lumen.reducer';
import { vendor } from './vendor/vendor.reducer';
import { others } from "./others/others.reducer"
import { dailyTest } from './daily-test/dailyTest.reducer';
import { scope3 } from './scope-3/scope3.reducer';
import { dataProvider } from "./data-provider/dataProvider.reducer"
import { target } from "./target/target.reducer"
import { notifications } from "./notifications/notifications.reducer"

export const settings = combineReducers({
    users,
    dimension,
    userRoles,
    faqs,
    superFormat,
    carouselFormat,
    customFormat,
    adTypeFormat,    
    billings,
    curatedMediaSettings,
    contextuals,
    mediagrid,
    lumen,
    vendor,
    scope3,
    others,
    dataProvider,
    dailyTest,
    target,
    notifications,
    bespokes
});

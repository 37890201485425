import FusionCharts from "fusioncharts";
FusionCharts.options.license({
    key: 'NvD4zyuD2A12B4D8D6F6E4C3E3J4A1C6D2jemH5B1bdjtmC5D4xhyA7A3A3A6F6D5H5A1E3B9A3A5E4E2E3G1lsmC8B2E6eC4B3I4xB-22nB5B2D3hlcG2A1A4xf1g1E2F4D1I3A4D6B3F4D1D3G4goiB9D2a1B4oixB2A16A7idE3OG1H2prcB2A6D2E4D3A2I3A5A4D5C4D3C2G4H-8==',
    creditLabel: false,
});

//The `FusionCharts.register()` API is used to register the new theme in the FusionCharts core.
FusionCharts.register('theme', {
    name: 'buddy-theme',
    theme: {
        base: {
            chart: {
                paletteColors: "#1272a4, #8cca8b, #e06e5e, #fec663, #01baef, #BC95DF, #67CDF2",
                showShadow: "0",
                showPlotBorder: "0",
                usePlotGradientColor: "0",
                showValues: "0",
                canvasBgAlpha: "0",
                bgAlpha: "100",
                showCanvasBorder: "0",
                showAlternateHGridColor: "0",
                divLineColor: "#DFDFDF",
                showXAxisLine: "0",
                yAxisNamePadding: "15",
                sYAxisNamePadding: "15",
                xAxisNamePadding: "15",
                captionPadding: "15",
                sYAxisNameFontColor: "#999",
                yAxisValuesPadding: "15",
                labelPadding: "10",
                transposeAxis: "1",
                toolTipBgColor: "#FFFFFF",
                toolTipPadding: "6",
                toolTipBorderColor: "#E1E1E1",
                toolTipBorderThickness: "1",
                toolTipBorderAlpha: "100",
                toolTipBorderRadius: "2",
                baseFontColor: "#5A5A5A",
                baseFontSize: "14",
                xAxisNameFontBold: "0",
                yAxisNameFontBold: "0",
                sYAxisNameFontBold: "0",
                xAxisNameFontSize: "15",
                yAxisNameFontSize: "15",
                sYAxisNameFontSize: "15",
                captionFontSize: "18",
                captionFontFamily: "Ubuntu",
                baseFont: "Ubuntu",

                subCaptionFontSize: "13",
                captionFontBold: "1",
                subCaptionFontBold: "0",
                subCaptionFontColor: "#999",
                valueFont: "Ubuntu",

                drawCustomLegendIcon: "1",
                legendShadow: "0",
                legendBorderAlpha: "0",
                legendBorderThickness: "0",
                legendItemFont: "Ubuntu",
                legendIconBorderThickness: "0",
                legendBgAlpha: "0",
                legendItemFontSize: "15",
                legendCaptionFontColor: "#999",
                legendCaptionFontSize: "13",
                legendCaptionFontBold: "0",
                legendScrollBgColor: "#FFF",

                crossLineAnimation: "1",
                crossLineAlpha: "100",
                crossLineColor: "#DFDFDF",
                showHoverEffect: "1",
                plotHoverEffect: "1",
                plotFillHoverAlpha: "90",
                barHoverAlpha: "90",
                bgColor: "#FFFFFF",
                borderThickness: "1",
                borderRadius: "10",
                borderAlpha: "100",
                borderColor: "#D9D9D9",
                showBorder: "1",
                labelFontColor: "#202124", // xval
                valueFontColor: "#787575",
                yAxisValueFontColor: "#787575", //yval
                yAxisNameFontColor: "#A6A6A6", // ylabel
                xAxisNameFontColor: "#A6A6A6",
                xAxisValueFontColor: "#202124",
                legendItemFontColor: "#A6A6A6",
                captionFontColor: "#202124",

            },
        },
        multiaxisline: {
            chart: {
                showLegend: "1",
                lineThickness: "2",
                allowSelection: "0",
                connectNullData: "1",
                drawAnchors: "1",
                divLineDashed: "0",
                divLineColor: "#DFDFDF",
                vDivLineColor: "#DFDFDF",
                vDivLineDashed: "0",
                yAxisNameFontSize: "13",
                drawCustomLegendIcon: "0",
                showhovereffect: "1",
                drawcrossline: "1",
            },

            axis: [{ divLineColor: "#DFDFDF", setAdaptiveYMin: "1", divLineDashed: "0" }],
        },
        sparkline: {
            chart: {
                plotFillColor: "#DFDFDF",
                anchorRadius: "4",
                highColor: "#8CCA8B", //Buddy data C1
                lowColor: "#E06E5E", //Buddy data B1
                captionPosition: "top",

                showOpenAnchor: "0 !important",
                showCloseAnchor: "0 !important",
                showOpenValue: "0 !important",
                showCloseValue: "0 !important",
                showHighLowValue: "0 !important",


                periodColor: "#F3F3F3",
                showBorder: "0",
                lineColor: "#D9D9D9"
            },
        },
        bar2d: { chart: { placeValuesInside: "0", showAlternateVGridColor: "0", yAxisValuesPadding: "10", } },
        mscombidy2d: {
            chart: {
                paletteColors: "#89BCD8, #A01A7D, #e06e5e, #fec663, #01baef, #BC95DF, #67CDF2 !important",
                drawCustomLegendIcon: "0 !important",
                lineThickness: "2", anchorRadius: "4", drawCrossLine: "1", showLegend: "1", anchorHoverEffect: "1", anchorHoverRadius: "4",
                anchorBorderHoverThickness: "1.5", anchorBgHoverColor: "#FFFFFF", legendIconBorderThickness: "1",
                maxLabelHeight: "100",
                decimals: "0", labelPadding: "23"
            },
        },
        HLinearGauge: { chart: { chartLeftMargin: "30", chartRightMargin: "30", ledGap: "0", showGaugeBorder: "0", setAdaptiveMin: "1", adjustTM: "1", placeTicksInside: "0", autoAlignTickValues: "1", minortmnumber: "0", majorTMHeight: "8", majorTMAlpha: "20" } },
        angulargauge:{chart:{setAdaptiveMin:"1",adjustTM:"1",tickvaluedistance:"10",placeTicksInside:"0",autoAlignTickValues:"1",showGaugeBorder:"0",minortmnumber:"0",majorTMHeight:"8",gaugeFillMix:"{light-0}",pivotbgcolor:"#000000",pivotfillmix:"0",showpivotborder:"1",pivotBorderColor:"#FFFFFF",showValue:"0",valueBelowPivot:"1"},dials:{dial:[{bgColor:"#000000",borderThickness:"0"}]}},
        msspline: { chart: { paletteColors: "#a2137d !important", lineThickness: "2", anchorRadius: "4", anchorHoverEffect: "1", anchorHoverRadius: "4", anchorBorderHoverThickness: "1.5", anchorBgHoverColor: "#FFFFFF", legendIconBorderThickness: "1", showLegend: "1", legendIconSides: "2" } },
        overlappedbar2d:
        {
            chart:
            {
                paletteColors: "#D9D9D9, #A01A7D !important",
                placeValuesInside: "0", showAlternateVGridColor: "0", showLegend: "1", legendIconSides: "4", yAxisValuesPadding: "25"
            }
        },
        stackedcolumn2dlinedy:{
            chart:{
                lineThickness:"2",
                anchorRadius:"4",
                drawCrossLine:"1",showLegend:"1",drawCustomLegendIcon:"0",anchorHoverEffect:"1",anchorHoverRadius:"4",anchorBorderHoverThickness:"1.5",anchorBgHoverColor:"#FFFFFF",legendIconBorderThickness:"1"
            }
        }
    }
});
FusionCharts.register('theme', {
    name: 'buddy-amplified',
    theme: {
        base: {
            chart: {
                paletteColors: "#1272a4, #8cca8b, #e06e5e, #fec663, #01baef, #BC95DF, #67CDF2",
                showShadow: "0",
                showPlotBorder: "0",
                usePlotGradientColor: "0",
                showValues: "0",
                canvasBgAlpha: "0",
                bgAlpha: "100",
                showCanvasBorder: "0",
                showAlternateHGridColor: "0",
                divLineColor: "#DFDFDF",
                showXAxisLine: "0",
                yAxisNamePadding: "15",
                sYAxisNamePadding: "15",
                xAxisNamePadding: "15",
                captionPadding: "15",
                sYAxisNameFontColor: "#999",
                yAxisValuesPadding: "15",
                labelPadding: "10",
                transposeAxis: "1",
                toolTipBgColor: "#FFFFFF",
                toolTipPadding: "6",
                toolTipBorderColor: "#E1E1E1",
                toolTipBorderThickness: "1",
                toolTipBorderAlpha: "100",
                toolTipBorderRadius: "2",
                baseFontColor: "#5A5A5A",
                baseFontSize: "14",
                xAxisNameFontBold: "0",
                yAxisNameFontBold: "0",
                sYAxisNameFontBold: "0",
                xAxisNameFontSize: "15",
                yAxisNameFontSize: "15",
                sYAxisNameFontSize: "15",
                captionFontSize: "18",
                captionFontFamily: "Ubuntu",
                baseFont: "Ubuntu",

                subCaptionFontSize: "13",
                captionFontBold: "1",
                subCaptionFontBold: "0",
                subCaptionFontColor: "#999",
                valueFont: "Ubuntu",

                drawCustomLegendIcon: "1",
                legendShadow: "0",
                legendBorderAlpha: "0",
                legendBorderThickness: "0",
                legendItemFont: "Ubuntu",
                legendIconBorderThickness: "0",
                legendBgAlpha: "0",
                legendItemFontSize: "15",
                legendCaptionFontColor: "#999",
                legendCaptionFontSize: "13",
                legendCaptionFontBold: "0",
                legendScrollBgColor: "#FFF",

                crossLineAnimation: "1",
                crossLineAlpha: "100",
                crossLineColor: "#DFDFDF",
                showHoverEffect: "1",
                plotHoverEffect: "1",
                plotFillHoverAlpha: "90",
                barHoverAlpha: "90",
                bgColor: "#FFFFFF",
                borderThickness: "1",
                borderRadius: "10",
                borderAlpha: "100",
                borderColor: "#D9D9D9",
                showBorder: "1",
                labelFontColor: "#202124", // xval
                valueFontColor: "#787575",
                yAxisValueFontColor: "#787575", //yval
                yAxisNameFontColor: "#A6A6A6", // ylabel
                xAxisNameFontColor: "#A6A6A6",
                xAxisValueFontColor: "#202124",
                legendItemFontColor: "#A6A6A6",
                captionFontColor: "#202124",

            },
        },
        stackedcolumn2dlinedy:{
            chart:{
                paletteColors: "#A01A7D, #F9D7F0, #1272A4, #fec663, #01baef, #BC95DF, #67CDF2 !important",
                drawCustomLegendIcon: "0 !important",
                lineThickness: "2", anchorRadius: "4", drawCrossLine: "1", showLegend: "1", anchorHoverEffect: "1", anchorHoverRadius: "4",
                maxLabelHeight: "100",
                decimals: "0", labelPadding: "10",
                
                anchorBorderHoverThickness:"1.5",anchorBgHoverColor:"#FFFFFF",legendIconBorderThickness:"1"
            }
        }
    }
});

FusionCharts.register('theme', {
    name: 'buddy-theme-dark',
    theme: {
        base: {
            chart: {
                paletteColors: "#1272a4, #8cca8b, #e06e5e, #fec663, #01baef, #BC95DF, #67CDF2",
                showShadow: "0",
                showPlotBorder: "0",
                usePlotGradientColor: "0",
                showValues: "0",
                canvasBgAlpha: "0",
                bgAlpha: "100",
                showCanvasBorder: "0",
                showAlternateHGridColor: "0",
                divLineColor: "#DFDFDF",
                showXAxisLine: "0",
                yAxisNamePadding: "15",
                sYAxisNamePadding: "15",
                xAxisNamePadding: "15",
                captionPadding: "15",
                sYAxisNameFontColor: "#c0c0c0",
                pYAxisNameFontColor: "#c0c0c0",
                yAxisValuesPadding: "15",
                labelPadding: "10",
                transposeAxis: "1",
                toolTipBgColor: "#FFFFFF",
                toolTipPadding: "6",
                toolTipBorderColor: "#E1E1E1",
                toolTipBorderThickness: "1",
                toolTipBorderAlpha: "100",
                toolTipBorderRadius: "2",
                baseFontColor: "#5A5A5A",
                baseFontSize: "14",
                xAxisNameFontBold: "0",
                yAxisNameFontBold: "0",
                sYAxisNameFontBold: "0",
                xAxisNameFontSize: "15",
                yAxisNameFontSize: "15",
                sYAxisNameFontSize: "15",
                captionFontSize: "18",
                captionFontFamily: "CenturyGothic, sans-serif",
                baseFont:"CenturyGothic, sans-serif",
                
                subCaptionFontSize: "13",
                captionFontBold: "1",
                subCaptionFontBold: "0",
                subCaptionFontColor: "#999",
                valueFont: "CenturyGothic, sans-serif",

                drawCustomLegendIcon: "1",
                legendShadow: "0",
                legendBorderAlpha: "0",
                legendBorderThickness: "0",
                legendItemFont: "CenturyGothic, sans-serif",
                legendIconBorderThickness: "0",
                legendBgAlpha: "0",
                legendItemFontSize: "15",
                legendCaptionFontColor: "#999",
                legendCaptionFontSize: "13",
                legendCaptionFontBold: "0",
                legendScrollBgColor: "#FFF",
                legendItemFontColor: "#c0c0c0",

                crossLineAnimation: "1",
                crossLineAlpha: "100",
                crossLineColor: "#DFDFDF",
                showHoverEffect: "1",
                plotHoverEffect: "1",
                plotFillHoverAlpha: "90",
                barHoverAlpha: "90",
                bgColor: "#2a3143",
                borderThickness: "1",
                borderColor: "#808080",
                showBorder: "1",
                labelFontColor: "#FFFFFF",
                valueFontColor: "#FFFFFF",
                yAxisValueFontColor: "#FFFFFF",
                yAxisNameFontColor: "#FFFFFF",
                xAxisNameFontColor: "#FFFFFF",
                xAxisValueFontColor: "#FFFFFF",
                
                captionFontColor: "#FFFFFF",


            },
        },
        multiaxisline: {
            chart: {
                showLegend: "1",
                lineThickness: "2",
                allowSelection: "0",
                connectNullData: "1",
                drawAnchors: "1",
                divLineDashed: "0",
                divLineColor: "#DFDFDF",
                vDivLineColor: "#DFDFDF",
                vDivLineDashed: "0",
                yAxisNameFontSize: "13",
                drawCustomLegendIcon: "0",
                showhovereffect: "1",
                drawcrossline: "1",
            },
            
            axis: [{ divLineColor: "#DFDFDF", setAdaptiveYMin: "1", divLineDashed: "0" }],
        },
        sparkline: {
            chart: {
                plotFillColor: "#DFDFDF",
                anchorRadius: "4",
                highColor: "#32cd30",
                lowColor: "#ec1555",
                captionPosition: "top",

                showOpenAnchor: "0 !important",
                showCloseAnchor: "0 !important",
                showOpenValue: "0 !important",
                showCloseValue: "0 !important",
                showHighLowValue: "0 !important",
                

                periodColor: "#F3F3F3",
                showBorder: "0",
                lineColor:"#4fadc5"
            },
        },
        bar2d: { chart: { placeValuesInside: "0", showAlternateVGridColor: "0", yAxisValuesPadding: "10", } },
        mscombidy2d: {
            chart: {
                paletteColors: "#1272a4, #A01A7D, #e06e5e, #fec663, #01baef, #BC95DF, #67CDF2 !important",
                drawCustomLegendIcon: "0 !important",
                lineThickness: "2", anchorRadius: "4", drawCrossLine: "1", showLegend: "1", anchorHoverEffect: "1", anchorHoverRadius: "4",
                anchorBorderHoverThickness: "1.5", anchorBgHoverColor: "#FFFFFF", legendIconBorderThickness: "1",
                maxLabelHeight: "100",
                decimals: "0", labelPadding: "23"
            },
        },
    }
});

export default FusionCharts;
import React, {lazy, Suspense, Component } from "react";
import { Link } from "react-router-dom";
import History from "../../helpers/history";
import { addClass, isPerformanceOpen, removeClass } from "../utilities/customFunctions"
import UserAvatar from '../utilities/UserAvatar'
import { connect } from 'react-redux';
import { authUserSignOut, checkIfLoggedIn, saveBuddyLocation, saveShowcaseLocation } from '../../redux/user/user.actions';
import { pushLocation} from '../utilities/customRoutes'
import { getQueryStringParams } from '../utilities/customFunctions'

import { items } from './items'

import classnames from 'classnames';
import { SocketContext } from "../../socket";
//import ShowcaseMenu from './showcase'
import { Loader } from '../react-overlay-loader';
import "./menu.scss"
import { getNotifications, markNotificationRead } from "../../redux/settings/notifications/notifications.actions";
import { C_LEVEL, SUPER_ADMIN } from "../../helpers/roles";
import NotificationsPopup from "../render-helpers/NotificationsPopup";
const ShowcaseMenu = lazy(() => import('./showcase'));

class SideMenu extends Component {

  constructor(props){
    super(props);
    let pathname = this.props.location.pathname;
    let showBuddyMenu = true;

    if(pathname.indexOf("showcase") > -1){
      showBuddyMenu = false;
    }

    this.state = {
      showBuddyMenu: showBuddyMenu,
      isMobile: window.innerWidth < 576
    }

    
  }

  static contextType = SocketContext;

  componentDidMount(){
    let pathname = this.props.location.pathname;
    this.initMenu(pathname)
    
    let isSideBarExpanded = localStorage.getItem("bb-sidebar-expanded");
    if(this.state.isMobile || isSideBarExpanded === "false"){
      this.collpaseSidebar();
    }
  }
  componentDidUpdate(prevProps, prevState){
    let pathname = this.props.location.pathname;
    if((pathname !== prevProps.location.pathname) || (this.props.role !== prevProps.role)){
      this.initMenu(pathname)
    } 

  }

  initMenu(pathname){
    let buddyMenu = document.getElementById("bb-buddy-menu");
    let showcaseMenu = document.getElementById("bb-showcase-menu");

    if(pathname.indexOf("showcase") > -1){
      if(buddyMenu){
        buddyMenu.style.display = "none";
      }
      if(showcaseMenu){
        showcaseMenu.style.display = "block";
      }      
      this.setState({showBuddyMenu: false})
    }
    else{
      if(showcaseMenu){
        showcaseMenu.style.display = "none";
      }
      if(buddyMenu){
        buddyMenu.style.display = "block";
      }
      this.setState({showBuddyMenu: true})

    }
  }

  changemenu(name, changeSelection = true) {
    
    let searchParams = getQueryStringParams(this.props.location.search);
    if (changeSelection) {
      //this.deactivatemenu();
      removeClass("dropdown-line", "active");
      removeClass("dropdown-content", "active");
      removeClass("bb-menu-item", "active");
      removeClass("dropdown", "active");
      removeClass("dropdown-icon", "active");
    }
    var parentId;
    /* if (document.getElementById(name) === null) {
      pushLocation("dashboard");
      addClass(document.getElementById("dashboard"), "active");
    }
    else { */
    if (searchParams && searchParams.campaignID) {
      if (name === "flights") {
        return;
      }
    }
    if (document.getElementById(name)) {
      changeSelection && addClass(document.getElementById(name), "active");
      if (document.getElementById(name).parentNode.className === "dropdown") {
        parentId = document.getElementById(name).parentNode.id;
        parentId = parentId.replace("-dropdown", "");
        removeClass("dropdown", "active");
        addClass(document.getElementById(parentId + "-dropdown"), "active")
        addClass(document.getElementById(parentId + "-line"), "active")
        
        if(document.getElementById(parentId+"-dropdown-icon")) {
          addClass(document.getElementById(parentId+"-dropdown-icon"), "active")
        }
      }
      else {
        if (document.getElementById(name).parentNode.className === "menu-dropdown") {
          parentId = name;
          
          removeClass("dropdown-icon", "active");
          if(document.getElementById(parentId + "-dropdown").className.includes("active")){
            removeClass("dropdown", "active");
          } else {
            removeClass("dropdown", "active");
            addClass(document.getElementById(parentId + "-dropdown"), "active")

            if(document.getElementById(parentId+"-dropdown-icon")) {
              addClass(document.getElementById(parentId+"-dropdown-icon"), "active")
            }
          }
          
        }
      }
    }

    //}
  }
  /* deactivatemenu = (e) => {
    removeClass("side-nav", "bb-translateX_0");
    removeClass("bb-container-main", "bb-overlay-main");
  } */

 /*  activatemenu = (e) => {
    addClass(document.getElementById("side-nav"), "bb-translateX_0");
    addClass(document.getElementById("bb-container-main"), "bb-overlay-main");
  } */

  expandMenu = (name) => {
    this.changemenu(name, false);
  }

  init() {
    var name = History.location.pathname;
    name = name.split("/");
    if (name && name.length > 2 && name[2]) {
      name = name[1] + "-" + name[2];
    }
    else {
      name = name[1];
    }

    this.changemenu(name);
  }

  checkAccess = () => {
    if( ( window.innerWidth < 576 ) || ( window.innerHeight <= 600 ) ) this.collpaseSidebar();
    this.props.checkIfLoggedIn();
  }

  collpaseSidebar = () => {   
    localStorage.setItem("bb-sidebar-expanded", false);

    // document.body.style.overflow = "hidden";
    if(this.state.isMobile){
      document.getElementById("side-nav").setAttribute('style', 'transform:translateX(-100%);')
      document.getElementById("bb-user-info-container").setAttribute('style', 'transform:translateX(-100%);')
      document.getElementById("bb-container-main").style.left = "0px";
      document.getElementById("bb-container-main").style.width = "100%";
    } else{
      document.getElementById("side-nav").setAttribute('style', 'transform:translateX(-74%); overflow:hidden; padding-right: 8px;')
      document.getElementById("bb-user-info-container").setAttribute('style', 'transform:translateX(-74%);')
      document.getElementById("bb-container-main").style.left = "65.5px";
      document.getElementById("bb-container-main").style.width = "calc(100% - 65.5px)";
    }
    document.getElementById("bb-buddy-logo-small").style.visibility = "hidden";
    document.getElementById("bb-menu-burger-icon-e").style.display = "block";
    document.getElementById("bb-menu-burger-icon-c").style.display = "none";
    document.getElementById("bb-menu-container-extras").style.display = "none";
    document.getElementById("bb-menu-footer").style.display = "none";      
    document.getElementById("side-nav-overlay").style.display ="none"; 
  }

  expandSidebar = () => {   
    localStorage.setItem("bb-sidebar-expanded", true);

    // document.body.style.overflow = "auto";
    document.getElementById("side-nav").setAttribute('style', 'transform:translateX(0%); overflow:auto; padding-right: 20px;')
    document.getElementById("bb-user-info-container").setAttribute('style', 'transform:translateX(0%); overflow:auto; padding-right: 20px;')
    if(this.state.isMobile) document.getElementById("side-nav-overlay").style.display ="block";
    else{
      document.getElementById("bb-container-main").style.left = "250px";
      document.getElementById("bb-container-main").style.width = "calc(100% - 250px)";
    }
    document.getElementById("bb-buddy-logo-small").style.visibility = "visible";
    document.getElementById("bb-menu-burger-icon-c").style.display = "block";
    document.getElementById("bb-menu-burger-icon-e").style.display = "none";
    document.getElementById("bb-menu-container-extras").style.display = "block";
    document.getElementById("bb-user-info-container").style.display = "block";
    
  }
  handleSidebarMobile = () => {
    if(localStorage.getItem("bb-sidebar-expanded") === "false") this.expandSidebar()
    else this.collpaseSidebar()
  }
  accessCheck = (item) => {
    const { user } = this.props;
    let itemChecked = item
    if(isPerformanceOpen(user)){
      itemChecked.submenu = []
      itemChecked = {
        ...itemChecked,
        isLinkClickable: false,
        submenu: [
          {
              id: 1141,
              elId: "spend-tracking",
              link: "spend-tracking",
              name: "Spend Tracking",
              access: itemChecked.access,
          },
          {
              id: 1142,
              elId: "performance-tracking",
              link: "performance-tracking",
              name: "Performance Tracking",
              access: itemChecked.access,
          },
        ]
      }
    } else if(user.country_code !== "AU" && user.country_code !== "NZ") itemChecked = null
    return itemChecked
  }
  renderMenu(role) {
    const { subcode } = this.props;
    this.init();
    return (items.map(item => {
      let post = item?.accessCheck ? this.accessCheck(item) : item
      return (
      post?.access.includes(role + subcode) || post?.access.includes("ALL") ?
        <div key={post.id} className="menu-dropdown">
          {post.isLinkClickable ?
            post.isExternalLink ?
              <a href={post.link} target="_blank" rel="noopener noreferrer" className={"bb-menu-item menu-item_" + post.color} >
                {post.name} <i className="fas fa-external-link-alt"></i>
              </a>
              :
              <Link to={"/" + post.link} id={post.elId || post.link} className={"bb-menu-item d-flex flex-row menu-item_" + post.color} onClick={this.checkAccess} >
                <span>{post.name}</span>
                {/* {post.name === "Help" && <span className="bb-menu-online-dot chatlio-online-dot align-self-center">●</span>} */}
              </Link>

            :
            <div id={post.elId || post.link} className={"bb-menu-item bb-menu-item-not-link menu-item_" + post.color} onClick={() => { this.expandMenu(post.link) }}>
              <div className="d-flex align-items-center justify-content-between"><span>{post.name}</span>
              <i className={"fas fa-angle-right bb-font_14px dropdown-icon"} id={(post.elId || post.link)+"-dropdown-icon"}></i>
              </div>
            </div>
          }

          {!post.isExternalLink &&
            <>
              <hr id={(post.elId || post.link) + "-line"} className={"dropdown-line slideInLeft bb-animate color_" + post.color} />
              <div id={(post.elId || post.link) + "-dropdown"} className="dropdown">
                {post.submenu && post.submenu.map(subpost => (
                  subpost.access?.includes(role + subcode) || subpost?.access.includes("ALL") ?
    
                    (subpost.goto ? 
                      // eslint-disable-next-line
                    <a key={subpost.id} id={(post.elId || post.link) + "-" + (subpost.elId || subpost.link)} className={"bb-animate fadeIn dropdown-content color_" + post.color} onClick={subpost.goto}>
                      {subpost.name}
                    </a> : 
                    <Link key={subpost.id} to={"/" + (subpost.customLink ? subpost.customLink : (post.link + "/" + subpost.link))} id={(post.elId || post.link) + "-" + (subpost.elId || subpost.link)} className={"bb-animate fadeIn dropdown-content color_" + post.color}
                      onClick={this.checkAccess}
                    >{subpost.name}</Link>)
                    : null
                ))}
              </div>
            </>
          }
        </div>
        : null
    )}
    ));
  }

  renderUser(user) {
    return (
      <div className="d-flex flex-column">
      <div id="bb-menu-footer" className="bb-menu-footer mt-3 mb-3 d-flex flex-row justify-content-start">
        
      {user && (user.role === SUPER_ADMIN || user.role === C_LEVEL) && <NotificationsPopup {...this.props}/>}

        <Link className="p-0 mr-3 align-self-center" to={"/my-profile"} onClick={this.checkAccess}>
          <UserAvatar imageSrc={user.profilePhoto} className="bb-user-avatar" firstName={user.firstName} lastName={user.lastName} />
        </Link>
        <div className="d-flex flex-column justify-content-center flex-grow-1 align-items-center">
          <div className="d-flex flex-column">
            <Link className="p-0 link-to-profile mb-2" to={"/my-profile"} onClick={this.checkAccess}><span className="hvr-underline-from-center profile-name-size">{`${user.firstName}`}</span></Link>
            {<span className="bb-text_small bb-color_primary bb-user-role">{user.roleName}</span>}
            <span className="bb-text_small hvr-underline-from-center text-white" onClick={() => {
              const socket = this.context;    
              // socket.disconnect();
              socket.volatile.emit("UserLogout")
              this.props.authUserSignOut(user.role, user.id)
            }}>Logout</span>
          </div>
        </div>
      </div>
      </div>
    )
  }

showBuddy = () =>{ 
  const { lastLocation } = this.props;
  this.props.saveShowcaseLocation(this.props.location.pathname, this.props.location.search);
  this.scrollMenuToTop();
  pushLocation(({ pathname: lastLocation.pathname, search: lastLocation.search }));
}

showShowcase = () =>{
  const { lastShowcaseLocation } = this.props;
  this.props.saveBuddyLocation(this.props.location.pathname, this.props.location.search)
  this.scrollMenuToTop();
  pushLocation(({ pathname: lastShowcaseLocation.pathname, search: lastShowcaseLocation.search }));
}

scrollMenuToTop = () => {
  setTimeout(function () {
    document.getElementById("side-nav").scroll({
      top: 0,
      //behavior: 'smooth' 
    })
  }, 100);
}

renderMenuToggler(){
  const { showBuddyMenu } = this.state;
  return(
    <div className="d-flex flex-row bb-menu-toggle justify-content-start">
      <div className={classnames("bb-menu-toggle-button ripple mr-2", showBuddyMenu ? "bb-menu-toggle-button-active" : "")} onClick={showBuddyMenu ? () => {pushLocation("/my-deals")} : this.showBuddy}>Buddy</div>
      <div className={classnames("bb-menu-toggle-button ripple", showBuddyMenu ? "" : "bb-menu-toggle-button-active")} onClick={!showBuddyMenu ? () => {pushLocation(`/showcase`)} : this.showShowcase}>Showcase</div>
    </div>
  )
}

  render() {
    const { showBuddyMenu } = this.state;
    const { authenticated, role, user, } = this.props;
    
    return (
      authenticated ? 
      <React.Fragment>
        <div id="side-nav-mobile" className="side-nav-mobile d-flex d-sm-none justify-content-between">
          <div id="bb-buddy-logo-small-mobile" onClick={() => pushLocation("/my-deals")} className="bb-buddy-logo-small cursor-pointer bb-buddy-logo-small-mobile align-self-center"/>
          <div id="bb-menu-burger-icon-c-mobile" className="menu-ec-arrow-close cursor-pointer" onClick={() => this.handleSidebarMobile()}></div>
        </div>
        <div id="side-nav" className="side-nav d-flex flex-column">
          <div className="w-100 bb-menu-logo-container d-none d-sm-flex justify-content-end py-4">
            <div id="bb-buddy-logo-small" onClick={() => pushLocation("/my-deals")} className="img-fluid bb-buddy-logo-small cursor-pointer"/>
            <div id="bb-menu-burger-icon-e" className="menu-ec-arrow-open cursor-pointer" onClick={() => this.expandSidebar()}></div>
            <div id="bb-menu-burger-icon-c" className="menu-ec-arrow-close cursor-pointer" onClick={() => this.collpaseSidebar()}></div>
          </div>
          <div className="menu-list-container">
            <div id="bb-menu-container-extras" >
              <div className="d-block d-sm-none" style={{marginTop: "70px"}}></div>
              {role && 
                <div id="bb-menu-container" className="d-flex flex-row bb-menu-container">
                  <div id="bb-buddy-menu" className="bb-buddy-menu">
                    {this.renderMenu(role)}
                  </div>
                  
                  {!showBuddyMenu ? 
                    
                    <Suspense fallback={<Loader containerStyle={{ backgroundColor: "transparent" }} loading={true} fullPage text="Loading..." />}>
                      <ShowcaseMenu {...this.props} role={role} />
                    </Suspense>
                    :
                    null
                  }
                  
                </div>
              }
              {this.renderMenuToggler()}
            </div>
            
          </div>
          <div id="bb-user-info-container">
            {authenticated && this.renderUser(user)}
          </div>
        </div>
        <div id="side-nav-overlay" onClick={() => this.collpaseSidebar()}></div>
      </React.Fragment>
      : null
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.user.authenticated,
    role: state.user.role,
    subcode: state.user.subcode || 0,
    user: state.user,
    lastLocation: state.user.location || {pathname:"/my-deals",search: ""},
    lastShowcaseLocation: state.user.showcaseLocation || {pathname:"/showcase",search: ""}
  }
};


const mapDispatchToProps = {
  authUserSignOut, checkIfLoggedIn, saveBuddyLocation, saveShowcaseLocation, getNotifications, markNotificationRead
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);


//export default SideMenu;
